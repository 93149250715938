// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyD2shuvBAknEKCAsKMPaKm-o9NZO-Lydyk",
  authDomain: "indbnew5.firebaseapp.com",
  databaseURL: "https://indbnew5-default-rtdb.firebaseio.com",
  projectId: "indbnew5",
  storageBucket: "indbnew5.appspot.com",
  messagingSenderId: "491932295854",
  appId: "1:491932295854:web:ad9b880f5fa0e831ef833f",
  measurementId: "G-PG2FBMHCY1"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };